import tutcode from "../images/tutcode.png";
import enjeck from "../images/enjeck.png";
import ll from "../images/libre-logos.svg";
import gfolio from "../images/gfolio.png";
import jpg2svg from "../images/jpg2svg.png";
import gg from "../images/geo-guess.png";
import blobby from "../images/blobby.png";
import cav from "../images/CryptographyVisualizer.png"
import li from "../images/linkedIn-icon.png";
import gh from "../images/github-icon.png";
import email from "../images/gmail-icon.png";
import placeholder from "../images/bg1.png";
export const ImagesContent = [
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216551009329877002/images.png?ex=6600cc6b&is=65ee576b&hm=a94aa4a18a751b97fd7595351a7129625aea15cb937eb6ee72dcb116a58208e3&",
    name: "Zerp Dab",
  },
  {
    link: "",
    img: 'https://cdn.discordapp.com/attachments/965694724369436762/1216544601738055680/images.png?ex=6600c673&is=65ee5173&hm=a73ba2cb7fd2724db2a449492dbfab32d4ccf069cb63af96ff0e77206058d9f2&',
    name: "Zerp Thumbs Up",
  },
  {
    link: "",
    img: 'https://cdn.discordapp.com/attachments/965694724369436762/1216545213636542474/2Q.png?ex=6600c705&is=65ee5205&hm=477cd737860690d8a9805be78b4ef01b8ebac16cde9aefee0352920ab1fee2d5&',
    name: "Zerp Flip The Bird",
  },
  {
    link: "",
    img: 'https://cdn.discordapp.com/attachments/965694724369436762/1216545131826774118/wG0tVEnoqC5YQAAAABJRU5ErkJggg.png?ex=6600c6f1&is=65ee51f1&hm=5571633cc36eb3752c7f47ca221bab4ce9fbdfab8d86ad2189ded02ccfa2afe2&',
    name: "Zerp Beats",
  },
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216550716735356968/2Q.png?ex=6600cc25&is=65ee5725&hm=395533ea05b9717d86f1dde21bef56f99fa8007f83c0675f4bd129fd76c50c8c&",
    name: "Zerp Hiii",
  },
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216550789628170301/images.png?ex=6600cc36&is=65ee5736&hm=46bc32808bf7ba84ef277b13d547aea88bef9877ac31cf78c38832025fdc61df&",
    name: "Zerp Loves You",
  },
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216550813745283233/images.png?ex=6600cc3c&is=65ee573c&hm=c82d66964a54a385a82d549a6e1515109903a6cf40e67c317d64594eaffce3e9&",
    name: "Zerp Needs Coffee",
  },
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216561412160356384/0ad12fc5804c40ad52afc691c37e8167.jpg?ex=6600d61b&is=65ee611b&hm=b41c81d415a6ed5d28a9fe03f8631c8ddf05cdfe26874fa1378a3ac5d37e683a&",
    name: "Zerp Tired",
  },
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216561451200811069/33402dfb365ece4a3c761f256b052dfe.jpg?ex=6600d624&is=65ee6124&hm=9af0769ae8914498b5a3c63a8ddd0228e39f705bcf1dbbc304d276176e1eef4f&",
    name: "Zerp Will BONK",
  },
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216561412969861130/7766328a08a8e1311b2f1f909dea3d79.jpg?ex=6600d61b&is=65ee611b&hm=53f6c0453af50e79fa886eb2109a04ce25dec510d54576bcb348d8fc7228caa1&",
    name: "Zerp Confusion",
  },
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216561412688838676/b0b3d0976b8df6ab386282ba35322cc4.jpg?ex=6600d61b&is=65ee611b&hm=1fe9701175b65a1432964d933e195d74d44f67f816192b3ef1ccad4bb4a57d02&",
    name: "Zerp Rock On",
  },
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216561452350046228/453959aac38e2a14080e63e4b4d54088.jpg?ex=6600d624&is=65ee6124&hm=eed731e0c7dce8a612e6c1cbd701010e7259592cecf09f13c3a01688213157d5&",
    name: "Zerp Pray"
  },
  {
    link: "",
    img: "https://cdn.discordapp.com/attachments/965694724369436762/1216561452098392114/9184610e8cf2fb7878a5068bec07e585.jpg?ex=6600d624&is=65ee6124&hm=cd66c5be1c430b54da0f8131c7be99ea5d4e52a90635343a51dac6f3720b8028&",
    name: "Zerp Angel"
  },
];
