export const Content = [
  {
    category: "about",
    link: `${window.location.origin}/about#me`,
    name: "Me, Myself and Zerp",
    excerpt: `Zerp the alpha autistic blod is the master of the meme verse. Draw your own Zerp blob's and send them into the memeverse`
  },
  {
    category: "about",
    link: `${window.location.origin}/about#skills`,
    name: "$ZERP Tokenomics",
    excerpt: ``
  },

  // {
  //   category: "works",
  //   link: "https://github.com/enjeck/TutCode",
  //   name: "TutCode - site to simultaneously  view tutorials and code",
  //   excerpt: `A website where people can simultaneously code and watch/read
  //   tutorials. It's a more convenient way to learn and practice
  //   how to code (with HTML, CSS and JavaScript) on the same page`
  // },
  // {
  //   category: "works",
  //   link: `${window.location.origin}`,
  //   name: "Gfolio (this website)",
  //   excerpt: `A personal website simulating the Google Search platform. Developed with
  //   HTML, CSSS and React`
  // },
  // {
  //   category: "works",
  //   link: "https://enjeck.com/",
  //   name: "Personal Website",
  //   excerpt: `My personal website, designed and developed from scratch. It
  //   features a lot of hover effects, custom styling and a blog.`
  // },
  // {
  //   category: "works",
  //   link: "https://github.com/enjeck/libre-logos",
  //   name: "Libre Logos",
  //   excerpt: `A project to provide free logos to open source projects.
  //   Website and logos designed and built by yours truly. 
  //   The website is responsive and the individual logo pages are 
  //   programmatically-generated.`
  // },
  // {
  //   category: "works",
  //   link: "https://github.com/enjeck/jpg2svg",
  //   name: "jpg2svg",
  //   excerpt: `An program to convert JPG/JPEG images into SVG. It comes with a web interface where 
  //   you can upload an image, have it converted to SVG in the server, and you can 
  //   download the converted SVG file if you want.`
  // },
  // {
  //   category: "works",
  //   link: "https://github.com/enjeck/CrazyPassword",
  //   name: "Crazy Password",
  //   excerpt: `A password validation game with ridiculous requirements. 
  //   Using Django for this project was overkill, but I really wanted to 
  //    play around with regular expressions in Python, and Django already had
  //    a password validation engine I could add unto.`
  // },
  // {
  //   category: "works",
  //   link: "https://github.com/enjeck/Geo-Guess",
  //   name: "Geo Guess",
  //   excerpt: `A distance guessing game. You are shown random cities around the world,
  //   and you have to guess how far you are from the random city. The better your
  //   guesss, the higher your score.`
  // },
  // {
  //   category: "works",
  //   link: "https://github.com/enjeck/btns",
  //   name: "btns",
  //   excerpt: `A collection of buttons with cool hover effects.`,
  // },
  // {
  //   category: "works",
  //   link: "https://github.com/enjeck/Blobby",
  //   name: "Blobby",
  //   excerpt: `
  //   Generative blob SVG characters using Python. No characters are the same! 
  //   Each Blobby character has a different body shape. The shape is always unique, 
  //   and the colors and eyes are randomly applied to each shape.  `
  // },
  // {
  //   category: "works",
  //   link: "https://github.com/enjeck/Google-Sheet-to-website",
  //   name: "Sheet to Site",
  //   excerpt: `An experiment with building a website directly from Google Sheets. You edit a spreadsheet,
  //   enter a link to the spreadsheet, and your changes are reflected on the website. `
  // },
  // {
  //   category: "works",
  //   link: "https://github.com/enjeck/CryptoAlgoVisualizer",
  //   name: "Crypto Algorithm Visualizer",
  //   excerpt: `Visualizations of various cryptography algorithms. 
  //   Currently has Caesar's Cipher and Mono Alphabetic Cipher.`,
  // },

  // {
  //   category: "writing",
  //   link: `${window.location.origin}`,
  //   name: "Zerp Saves The Day",
  //   excerpt: `In a surprising twist of fate, Zerp has developed a software that can automatically translate any online argument into a harmonious discussion, effectively saving the day from internet trolls. Critics argue it just replaces heated words with pictures of cats, but many agree it's a step in the right direction.`,
  // },
  // {
  //   category: "writing",
  //   link: `${window.location.origin}`,
  //   name: "Zerp Making Strides In The Philanthropy World",
  //   excerpt: `Zerp's new initiative to solve world hunger involves creating a global network of food-sharing apps, where leftovers from restaurants are teleported directly to those in need using a mysterious, yet-to-be-revealed technology. Skeptics are hungry for proof, while supporters are just hungry.`,
  // },
  // {
  //   category: "writing",
  //   link: `${window.location.origin}`,
  //   name: "Zerp Goes To The Moon!",
  //   excerpt: `Zerp's ambitious project to launch a series of Wi-Fi satellites around the moon to ensure astronauts never lose their internet connection has been met with mixed reactions. While some laud the initiative for keeping space travelers TikTok-accessible, others question if extraterrestrial vlogging is a necessary step for mankind.`,
  // },

  

  {
    category: "social",
    link: "https://twitter.com/ZerpCoin",
    name: "Twitter/X",
    excerpt: `Connect and share zerps`
  },
  {
    category: "social",
    link: "https://t.me/ZerpCommunity",
    name: "Telegram",
    excerpt: `Chat and share zerps`
  },

  {
    category: "buy",
    link: "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R&fixed=in",
    name: "Raydium",
    excerpt: `Buy $ZERP on Raydium`
  },
  {
    category: "buy",
    link: "https://jup.ag/",
    name: "Jupiter",
    excerpt: `Buy $ZERP on Jupiter`
  },

];
