import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import FilterMenu from "../components/filtermenu";
import "./drawPage.css"; // Make sure this is correctly pointing to your CSS file

const DrawPage = ({ results }) => {
  return (
    <div className="main" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <FilterMenu />

      {/* Adjusted section for the JS Paint app with inline styling */}
      <div style={{ flexGrow: 1, padding: '20px' }}>
        <iframe
          src="https://jspaint.app"
          style={{ width: '100%', height: '100%', minHeight: 'calc(100vh - 200px)' }} // Adjust minHeight based on Header and Footer height
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>

      <Footer />
    </div>
  );
};

export default DrawPage;
