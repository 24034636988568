import "./boxmenudrop.css";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/free-solid-svg-icons";
import logo from "../images/e-logo.png"
import li from "../images/linkedIn-icon.png"
import gh from "../images/github-icon.png"
import news from "../images/newspaper.png"
import suitcase from "../images/suitcase.png"
import email from "../images/gmail-icon.png"
import img from "../images/image.png"
import about from "../images/info.png"


const BoxMenuDrop = () => {
  const [isActive, setActive] = useState("false");

  // Toggle the dropdown
  const handleToggle = () => {
    setActive(!isActive);
  };

  // Hide the dropdown when user clicks outside the element
  function useOutsideHandler(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target.parentElement)) {
          setActive(!!isActive);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef);

  return (
    <div className="dropdown" onClick={handleToggle} ref={wrapperRef}>
      <FontAwesomeIcon className="fas fa-th dropbtn" icon={faTh} />
      <div
        id="drop"
        className={
          isActive
            ? "dropdown-content-cont dropdown-hide"
            : "dropdown-content-cont dropdown-show"
        }
      >
        <div className="drop-item">
          <Link className="drop-link" to="/">
            <img
              src="https://i0.wp.com/bane-tech.com/wp-content/uploads/2015/10/Z.png?ssl=1"
              alt=""
            />
            <p> Search </p>
          </Link>
          <Link className="drop-link" to="/about">
            <img
              src={about}
              alt=""
            />
            <p> About </p>
          </Link >
          {/* <Link  className="drop-link" to="/works">
            <img
              src={suitcase}
              alt=""
            />
            <p> Works </p>
          </Link > */}

          <Link className="drop-link" to="/buy">
            <img
              src="https://s1.coincarp.com/logo/1/raydium.png?style=200&v=1631063519"
              alt=""
            />
            <p> Buy </p>
          </Link >

          <Link className="drop-link" to="/draw">
            <img
              src="https://i.pinimg.com/236x/96/d9/f8/96d9f872425bdcf4584c3189df1c341e.jpg"
              alt=""
            />
            <p> Draw </p>
          </Link >

          <Link className="drop-link" to="/social">
            <img
              src="https://cdn.pixabay.com/photo/2017/10/17/20/42/social-network-icon-2862113_960_720.png"
              alt=""
            />
            <p> Social </p>
          </Link >

          <Link className="drop-link" to="/blog">
            <img
              src={news}
              alt=""
            />
            <p> Fake News </p>
          </Link >
          <Link className="drop-link" to="/images">
            <img
              src={img}
              alt=""
            />
            <p> Images </p>
          </Link >
          {/* <a className="drop-link" href="mailto:zerp@zerple.com">
            <img
              src={email}
              alt=""
            />
            <p> Email </p>
          </a> */}

        </div>
      </div>
    </div>
  );
};

export default BoxMenuDrop;
