import "./profilemenudrop.css";
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

const ProfileMenuDrop = () => {
  const [isProfileActive, setProfileActive] = useState("false");
  // Toggle the dropdown
  const handleToggle = () => {
    setProfileActive(!isProfileActive);
  };

   // Hide the dropdown when user clicks outside the element
  function useOutsideHandler(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target.parentElement)) {
          setProfileActive(!!isProfileActive);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperProfileRef = useRef(null);
  useOutsideHandler(wrapperProfileRef);

  return (
    <div
      className="profile dropdown"
      onClick={handleToggle}
      ref={wrapperProfileRef}
    >
      <img
        className="profile-pic dropbtn"
        src="https://cdn.discordapp.com/attachments/965694724369436762/1216870209596686467/Zerp1-min.png?ex=6601f5b2&is=65ef80b2&hm=1b6bb8a715d592b29caac004e35d6cccd009c910d34e25733c11d4869af06ed9&"
        alt="profile"
      />
      <div className="profile-hightlight-dropdown">
        <p> Zerple </p>
        <p> You Are Zerp </p>
        <p> BUY ZERP </p>
      </div>
      <div
        className={
          isProfileActive
            ? "profile-details-dropdown dropdown-hide"
            : "profile-details-dropdown dropdown-show"
        }
      >
        <div className="first-detail">
          <img
            className=""
            src="https://cdn.discordapp.com/attachments/965694724369436762/1216870209596686467/Zerp1-min.png?ex=6601f5b2&is=65ef80b2&hm=1b6bb8a715d592b29caac004e35d6cccd009c910d34e25733c11d4869af06ed9&"
            alt="profile"
          />
          <p className="detail-text"> Zerp Supreme </p>
          <p className="detail-text"> zerp zerp zerp </p>
          <a href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R&fixed=in"> Buy $ZERP </a>
        </div>
        <Link className="second-detail" to="/about">
          <FontAwesomeIcon className="fa-user-plus" icon={faUserPlus} />
          <p> about the zerp </p>
        </Link>
        {/* <div className="third-detail">
          <a href="https://github.com/"> Raydium</a>
        </div>
        <div className="third-detail">
          <a href="https://github.com/"> Orca</a>
        </div> */}
        <div className="fourth-detail">
          <a href="https://www.geckoterminal.com/solana/pools"> see charts </a> <span> • </span>
          <Link to="/blog"> fake news </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileMenuDrop;
