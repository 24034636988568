import "./logo.css";
import React, { useEffect } from "react";


const Logo = () => {
  useEffect(() => {
    const logoEl = document.querySelector(".logo-text");
    if (logoEl && logoEl.innerHTML.trim()) {
      const logoText = logoEl.textContent.trim(); // Capture the text content of the logo
      logoEl.innerHTML = ''; // Clear the original text
      
      // Define the new colors
      const colors = ["#9945FF", "#14F195", "#4ca4c4", "#7770e5"];

      for (let i = 0; i < logoText.length; i++) {
        const letter = document.createElement("h1");
        // Directly apply color from the array, cycling through with modulo
        letter.style.color = colors[i % colors.length];
        letter.textContent = logoText[i]; // Set letter text
        
        logoEl.appendChild(letter); // Append the letter to the logo container
      }
    }
  }, []);

  return (
    <div className="search-logo" style={{ position: 'relative', textAlign: 'center', marginTop: '20px' }}>
      {/* Image positioned absolutely within the relative container */}
      <img
        src="https://cdn.discordapp.com/attachments/965694724369436762/1217554263614558389/Zerpy.jpg?ex=660472c5&is=65f1fdc5&hm=44587564122d20cdaee45f0ab16659ca0eeb4eb086e483f96dfbe826a7cd1e2f&"
        alt="Logo Background"
        style={{
          position: 'absolute',
          width: '90%', // Adjust based on how you want the image to cover
          height: 'auto',
          top: '-22%', // Adjust to control how much the image overlaps with the text
          left: '50%',
          transform: 'translateX(-50%)', // Center the image horizontally
          zIndex: '-1', // Ensure the image stays behind the text
        }}
      />
      {/* Text positioned relatively on top of the image */}
      <div className="logo-text" style={{ position: 'relative', zIndex: '1', color: 'white' }}>Zerple</div>
    </div>
  );
};

export default Logo;
